import videojs from 'video.js';
videojs.use('*', function(player) {
  return {
    setSource: function(srcObj, next) {
      if (srcObj.ads && srcObj.ads.provider == 'media-tailor') {
        const sessionUrl = `${srcObj.ads.prefix}${srcObj.ads.session}`;
        videojs.xhr.post(sessionUrl, {
            body: JSON.stringify(srcObj.ads.postBody),
        }, (err, resp) => {
          const session = JSON.parse(resp.body);
          srcObj.src = `${srcObj.ads.prefix}${session.manifestUrl}`;
          srcObj.type = session.manifestUrl.includes('.mpd') ? 'application/dash+xml' : 'application/x-mpegurl';
          srcObj.tracking = `${srcObj.ads.prefix}${session.trackingUrl}`;
          next(null, srcObj);
        });
      } else {
        next(null, srcObj);
      }
    }
  };
});
