import videojs from 'video.js';
import './plugin.css';

const VERSION = '1.0.0';
const Plugin = videojs.getPlugin('plugin');
const Component = videojs.getComponent('Component');

const defaults = {
  now: null,
  then: Date.now(),
};

class Freewheel extends Plugin {

  constructor(player, options) {

    super(player);

    this.options = videojs.obj.merge(defaults, options);

    this.player.ready(() => {
      this.player.Simplestream().debug('Freewheel Plugin Loaded');
    });

  }

}

Freewheel.defaultState = {};
Freewheel.VERSION = VERSION;
videojs.registerPlugin('Freewheel', Freewheel);
export default Freewheel;