// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vjs-remaining-time.aws-mediatailor-ad-playing {
  color: transparent;
}

.vjs-s3bubble-mediatailor .vjs-ad-box {
  background: rgb(0 0 0 / 70%);
  color: #fff;
  padding: 0.5em;
  position: absolute;
  bottom: 40px;
  right: 10px;
  width: auto;
  font-size: 13px;
  border-radius: 3px;
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  opacity: 0;
}
.vjs-s3bubble-mediatailor .vjs-ad-box.animated {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/ads/aws/plugin.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,kCAAkC;EAClC,0CAA0C;EAC1C,uCAAuC;EACvC,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".vjs-remaining-time.aws-mediatailor-ad-playing {\n  color: transparent;\n}\n\n.vjs-s3bubble-mediatailor .vjs-ad-box {\n  background: rgb(0 0 0 / 70%);\n  color: #fff;\n  padding: 0.5em;\n  position: absolute;\n  bottom: 40px;\n  right: 10px;\n  width: auto;\n  font-size: 13px;\n  border-radius: 3px;\n  transition: opacity 1s ease-in-out;\n  -webkit-transition: opacity 1s ease-in-out;\n  -moz-transition: opacity 1s ease-in-out;\n  opacity: 0;\n}\n.vjs-s3bubble-mediatailor .vjs-ad-box.animated {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
