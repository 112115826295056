/* index.js
 * Looks for embed codes on the page with class "simplestream-autoload" 
 * and initializes a player instance.
 */

import player from './player.js';

(function(global) {

  let ssmp;

  function init() {
    var players = document.getElementsByClassName('simplestream-autoload');
    for (var i = 0; i < players.length; ++i) {
        initPlayer(players[i].id);
    }
  }

  function initPlayer(playerId) {
    ssmp = new player(playerId);
  }

  function destroyPlayer(playerId) {
    ssmp.player.dispose();
  }

  global.simplestream = {
    init: init,
    initPlayer: initPlayer,
    destroyPlayer: destroyPlayer
  }

})(window);

document.addEventListener('DOMContentLoaded', function() {
  window.simplestream.init();
}, false);