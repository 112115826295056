import videojs from 'video.js';
import './plugin.css';
import './middleware';

const VERSION = '1.0.0';
const Plugin = videojs.getPlugin('plugin');
const Component = videojs.getComponent('Component');

const defaults = {
  now: null,
  then: Date.now(),
  interval: 1000,
  delta: null,
  currentTime: 0,
  countDown: 0,
  currentAd: null,
  trackingUrl: null,
  src: null,
  isSeek: true,
  adIsPlaying: false,
  played: [],
  adPositions: [],
  sentTrackingBeacons: []
};
class AwsMediaTailor extends Plugin {

  constructor(player, options) {

    super(player);

    this.options = videojs.obj.merge(defaults, options);

    this.player.Simplestream().debug('AwsMediaTailor Plugin Loaded');
    this.player.addClass('vjs-s3bubble-mediatailor');

    this.player.on('loadstart', (_event) => {
      this.player.one("loadedmetadata", () => {
        if (this.player.currentSource().tracking) {
          this.player.controlBar.hide();
          this.player.markers({
            markerStyle: {
              "background-color": "#fbcf03",
              "border-radius": "0px",
              "z-index": "0",
              "pointer-events": "none"
            },
            onMarkerReached: function(marker) { },
            markers: [],
          });
          this.options.trackingUrl = this.player.currentSource().tracking;
          this.startTracking();
          this.buildUi();
          this.player.on('playing', this.ssaiPlay.bind(this, 'playing'));
          this.player.on('seeking', this.ssaiSeeking.bind(this, 'seeking'));
          this.player.on('seeked', this.ssaiSeeked.bind(this, 'seeked'));
          this.player.on('timeupdate', this.ssaiTime.bind(this, 'timeupdate'));
          this.player.on('click', this.ssaiClick.bind(this));
        }
      });
    });

  }

  /**
   * Fired on stream play.
   */
  ssaiPlay() {
    setTimeout(() => {
      this.options.isSeek = true;
    }, 2000);
  }

  /**
   * Fired when user is seeking using the seekbar.
   */
  ssaiSeeking() {
    console.log('Seeking ...');
    //if (this.options.currentTime < this.player.currentTime() && this.options.adIsPlaying) {
    //  this.player.currentTime(this.options.currentTime);
    //}
  }

  /**
   * Fired when user completes seeking.
   */
  ssaiSeeked() {
    console.log('Seeked');
    /*if (this.options.isSeek && !this.options.adIsPlaying) {
      let seeked = this.player.currentTime();
      let goSeek = this.findClosest(seeked, this.options.adPositions);
      if (goSeek && !this.options.played.includes(goSeek.availId)) {
        this.player.currentTime(goSeek.start);
        this.options.played.push(goSeek.availId);
        this.options.isSeek = false;
      }
    }*/
  }

  /**
   * Fires every second whilst stream is playing.
   */
  ssaiTime() {

    var currentAd = this.getCurrentAd();

    // Debug
    var msg = 'currentTime is ' + this.player.currentTime();
    if(currentAd) {
      msg += ' | Ad ' + currentAd.index + ' of ' + currentAd.count;
      msg += ' | ' + currentAd.start + ' to ' + currentAd.end;
      if(currentAd.click.clickThrough) {
        msg += ' | clickThrough ' + currentAd.click.clickThrough;
      } else {
        msg += ' | No clickThrough';
      }
    } else {
      msg += ' | Content is playing';
      //msg += ' | Next Ad at ' + this.findNextAd(this.player.currentTime(), this.options.adPositions).start;
    }

    //console.log(msg);

    this.hideProgressBarIfCurrentAd(currentAd);
    this.trackingEvents(currentAd);

    this.options.now = Date.now();
    this.options.delta = this.options.now - this.options.then;

    if(this.options.delta > this.options.interval) {
      this.options.then = this.options.now - (this.options.delta % this.options.interval);
      if (!this.player.paused()) {
        this.options.currentTime = this.player.currentTime();
        let ind = this.options.adPositions.findIndex(
          (x) => this.options.currentTime >= x.start && this.options.currentTime <= x.end
        );
        if (ind !== -1) {

          if (!this.options.played.includes(this.options.adPositions[ind].availId)) {
            this.options.played.push(this.options.adPositions[ind].availId);
          }

          let count = Math.round(this.options.adPositions[ind].end) - Math.round(this.options.adPositions[ind].start) - this.options.countDown;
          
          this.player.getChild('adBox').addClass('animated');
          this.player.getChild('adBox').updateTextContent(`Ad ${currentAd.index} of ${currentAd.count} | ${Math.abs(count)}s`);

          this.options.adIsPlaying = true;
          if (this.options.countDown < 0) {
            this.player.getChild('adBox').removeClass('animated');
          } else {
            this.options.countDown++;
          }

        } else {
          this.player.getChild('adBox').removeClass('animated');
          this.options.countDown = 0;
          this.options.adIsPlaying = false;
        }
      }

    }

  }

  /**
   * Fires when player is clicked.
   */
  ssaiClick(evt) {
    if(this.player.paused()) {
      if(evt.target.tagName === 'VIDEO') {
        var currentAd = this.getCurrentAd();
        if(!currentAd) {
          console.log('Ad Is Not Playing');
        } else if(!currentAd.click.clickThrough) {
          console.log('Ad Is Not Clickable');
        } else {
            console.log('Ad Is Clickable');
            window.open(currentAd.click.clickThrough, '_blank');
            if(Array.isArray(currentAd.click.clickTracking)) {
              currentAd.click.clickTracking.forEach((beaconUrl) => {
                this.sendBeacon(beaconUrl);
              });
            }
        }
      }
    }
  }

  /**
   * Send a request to adserver's beaconUrl.
   */
  sendBeacon(beaconUrl) {
    console.log('Beacon: ' + beaconUrl);
    videojs.xhr.get(beaconUrl, (err, resp) => {
      //console.log(err, resp);
    });
  }

  /**
   * Send a request to adserver's beaconUrl.
   */
  hideProgressBarIfCurrentAd(currentAd) {
    if(currentAd) {
      this.player.controlBar.progressControl.seekBar.hide();
      this.player.controlBar.remainingTimeDisplay.addClass('aws-mediatailor-ad-playing');
    } else {
      this.player.controlBar.progressControl.seekBar.show();
      this.player.controlBar.remainingTimeDisplay.removeClass('aws-mediatailor-ad-playing');
    }
    this.player.controlBar.show();
  }

  /**
   * Send tracking events.
   */
  trackingEvents(currentAd) {
    if(currentAd) {
      currentAd.events.forEach((event) => {
        if(parseInt(this.player.currentTime()) >= parseInt(event.start)) {
          if(!this.options.sentTrackingBeacons.includes(event.start + '-' + event.type)) {
            console.log('Send Event At ' + event.start + ': ' + event.type);
            if(Array.isArray(event.beaconUrls)) {
              event.beaconUrls.forEach((beaconUrl) => {
                this.sendBeacon(beaconUrl);
              });
            }
            this.options.sentTrackingBeacons.push(event.start + '-' + event.type);
          }
        }
      });
    }
  }

  /**
   * Extract Ad from this.options.adPosiitions based on currentTime.
   */
  getCurrentAd() {
    var currentAd =  null;
    this.options.adPositions.forEach((avail) => {
      avail.ads.forEach((ad) => {
        if(this.player.currentTime() >= ad.start && this.player.currentTime() < ad.end) {
          currentAd = ad;
          return false;
        }
      });
    });
    return currentAd;
  }

  findNextAd(x, arr) {
    var filteredArray = arr.filter(function(ad) {
      return ad.start > x;
    });
    return filteredArray[filteredArray.length - 1];
  }

  findClosest(x, arr) {
    var filteredArray = arr.filter(function(ad) {
      return x > ad.end;
    });
    return filteredArray[filteredArray.length - 1];
  }

  /**
   * Calls Media Tailor tracking endpoint and renders Ad Markers.
   */
  startTracking() {
    videojs.xhr.get(this.options.trackingUrl, (err, resp) => {

      const data = JSON.parse(resp.body);

      this.player.Simplestream().debug('GET Tracking ' + this.options.trackingUrl);

      let markers = [];

      if (Array.isArray(data.avails)) {

        // Reformat AWS response
        data.avails.forEach((avail) => {

          var ads = [];
          avail.ads.forEach((ad, index) => {

            var click = [];
            var events = [];
            ad.trackingEvents.forEach((event) => {
              if(event.eventType == 'clickThrough') {
                click[event.eventType] = event.beaconUrls[0];
              } else if(event.eventType == 'clickTracking') {
                click[event.eventType] = event.beaconUrls;
              } else {
                events.push({
                  start: event.startTimeInSeconds,
                  type: event.eventType,
                  beaconUrls: event.beaconUrls
                });
              }
            });

            ads.push({
              id: ad.adId,
              index: index+1,
              count: avail.ads.length,
              start: ad.startTimeInSeconds,
              end: ad.startTimeInSeconds + ad.durationInSeconds,
              duration: ad.durationInSeconds,
              events: events,
              click: Object.assign({}, click)
            });

          });

          this.options.adPositions.push({
            id: avail.availId,
            start: avail.startTimeInSeconds,
            end: avail.startTimeInSeconds + avail.durationInSeconds,
            duration: avail.durationInSeconds,
            ads: ads
          });

        });

        // Reformat AWS response
        this.options.adPositions.forEach((avail) => {
          markers.push({
            availId: avail.id,
            time: avail.start,
            duration: avail.duration,
            text: 'Ads',
          });
        });

        // Debug
        var msg = 'Found ' + this.options.adPositions.length + ' Avails\n';
        this.options.adPositions.forEach((avail, index) => {
          msg += '- Avail ' + (index+1) + ' of ' + this.options.adPositions.length + ': ' + Math.round(avail.start) + 's to ' + Math.round(avail.end) + 's (' + Math.round(avail.duration) + 's) and contains ' + avail.ads.length + ' Ads\n';
          avail.ads.forEach((ad, index) => {
            msg += '  - Ad ' + ad.index + ' of ' + ad.count + ': ' + Math.round(ad.start) + 's to ' + Math.round(ad.end) + 's > ' + (ad.click.clickThrough ? ad.click.clickThrough : 'No Click') + '\n';
          });
        });
        console.log(msg);

      }

      this.player.markers.reset(markers);

    });
  }

  /**
   * Renders Ad Countdown.
   */
  buildUi() {
    class adBox extends Component {
      constructor(player, options) {
        super(player, options);
        if (options.text) {
          this.updateTextContent(options.text);
        }
      }
      createEl() {
        return videojs.dom.createEl('div', {
          className: 'vjs-ad-box'
        });
      }
      updateTextContent(text) {
        if (typeof text !== 'string') {
          text = '';
        }
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), text);
      }
    };
    videojs.registerComponent('adBox', adBox);
    this.player.addChild('adBox', { text: '' });
  }
}

AwsMediaTailor.defaultState = {};
AwsMediaTailor.VERSION = VERSION;
videojs.registerPlugin('AwsMediaTailor', AwsMediaTailor);
export default AwsMediaTailor;