import videojs from 'video.js';
import videojscontribads from 'videojs-contrib-ads';
import videojsima from 'videojs-ima';
import 'videojs-ima/dist/videojs.ima.css';
import './plugin.css';

const VERSION = '1.0.0';
const Plugin = videojs.getPlugin('plugin');
const Component = videojs.getComponent('Component');

const defaults = {
  duration: null,
  cuepoints: null,
  preroll_vast: null,
  midroll_vast: null,
};

class GoogleIma extends Plugin {

  constructor(player, options) {

    super(player);

    this.options = videojs.obj.merge(defaults, options);

    this.player.ready(() => {
        this.player.Simplestream().debug('GoogleIma Plugin Loaded');
        if(typeof google === "undefined") {
          this.player.Simplestream().errorScreen('Google Ads are not enabled in Player Settings')
        } else {
          this.init();
        }
    });

  }

  init() {

    if(this.player.options.getParams.testAds) {
      this.options.preroll_vast = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
      this.options.midroll_vast = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
    }

    if(this.player.options.getParams.noPreroll) {
      this.options.preroll_vast = null;
    }

    if(this.player.options.getParams.noMidroll) {
      this.options.midroll_vast = null;
    }

    this.player.Simplestream().debug('Duration: ' + this.options.duration);
    this.player.Simplestream().debug('Cuepoints: ' + (this.options.cuepoints ? this.options.cuepoints.join(',') : 'None'));
    this.player.Simplestream().debug('VAST Preroll: ' + this.options.preroll_vast);

    this.player.ima({
      adTagUrl: this.options.preroll_vast
    });

    // Are midrolls enabled?
    if(this.options.cuepoints && this.options.midroll_vast) {

      var markers = [];
      this.options.cuepoints.forEach((cuepoint) => {
        markers.push({
          time: cuepoint,
          duration: 5,
          text: 'Ads',
        });
      });

      this.player.markers({
          markerStyle: {
            'background-color': '#fbcf03',
            'border-radius': '0px',
            'z-index': '0',
            'pointer-events': 'none',
            'min-width': '5px',
            'max-width': '10px',
          },
          onMarkerReached: function(marker) { },
          markers: markers,
      });

      this.midroll = false;

      this.player.on('timeupdate', this.requestMidroll.bind(this, 'timeupdate'));

    }

  }

  requestMidroll() {
    var currentTime = this.player.currentTime();
    this.options.cuepoints.forEach((cuepoint) => {
      if(currentTime >= cuepoint) {
        this.player.Simplestream().debug('VAST Midroll at ' + cuepoint + ': ' + this.options.midroll_vast);
        this.options.cuepoints = this.options.cuepoints.filter(e => e !== cuepoint);
        this.player.ima.changeAdTag(this.options.midroll_vast);
        this.player.ima.requestAds();
      }  
    });

}

}

GoogleIma.defaultState = {};
GoogleIma.VERSION = VERSION;
videojs.registerPlugin('GoogleIma', GoogleIma);
export default GoogleIma;