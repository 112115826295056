import videojs from 'video.js';
import videojscontribads from 'videojs-contrib-ads';
import videojsima from 'videojs-ima';
import 'videojs-ima/dist/videojs.ima.css';
import './plugin.css';

const VERSION = '1.0.0';
const Plugin = videojs.getPlugin('plugin');
const Component = videojs.getComponent('Component');

const defaults = {
  duration: null,
  cuepoints: null,
  preroll_vast: null,
  midroll_vast: null,
};

class GoogleVideoStitcher extends Plugin {

  constructor(player, options) {

    super(player);

    this.options = videojs.obj.merge(defaults, options);

    this.player.ready(() => {
        this.player.Simplestream().debug('GoogleVideoStitcher Plugin Loaded');
        if(typeof google === "undefined") {
          this.player.Simplestream().errorScreen('Google Ads are not enabled in Player Settings')
        } else {
          this.init();
        }
    });

  }

  init() {
    this.player.Simplestream().errorScreen('Google Video Stitcher has not been implemented yet.')
  }

}

GoogleVideoStitcher.defaultState = {};
GoogleVideoStitcher.VERSION = VERSION;
videojs.registerPlugin('GoogleVideoStitcher', GoogleVideoStitcher);
export default GoogleVideoStitcher;